@import "_common.scss";
.office {
  // Spacing between sections that are not the office details first section.
  > section:not(:first-of-type) + section {
    margin-top: $section-space;
  }

  // Bottom spacing between last section and footer.
  > section:last-of-type {
    margin-bottom: $section-space;
  }

  .office-photos.spacing-full {
    padding: 4.16667vw 5.55556vw;
  }

  .body.body-neo {
    .accordion-section {
      margin-left: auto;
      margin-right: auto;
      background-color: $grey-darkest;
      color: $white;

      // adding margin for the blue accordions that are last in the body
      &.last {
        margin-bottom: $column;
      }
    }
  }

  // If out headline has no images and our office has no children, change the background to a light gray.
  .headline.fallback + .office-details.no-children {
    background-color: rgba($grey-darkest, 0.04);
    color: $black;

    .office-link {
      color: $black;
      border-color: $black;

      svg {
        fill: $black;
      }
    }

    .button {
      background: $grey-darkest;
      color: $white;
    }

    .social-connect-icons {
      color: $black;

      .social-icon {
        fill: $black;
      }
    }

    .other-offices > button {
      color: $black;
    }
  }

  .office-details {
    background-color: $white-smoke;
    color: $black;

    > .location {
      padding-top: $column * 0.75;
      padding-bottom: $column * 0.75;
    }

    // UK has multiple locations.
    .additional-locations .location {
      padding-bottom: $column * 0.75;
    }

    .location {
      justify-content: space-between;

      &:not(:first-child) {
        padding-top: 0;
      }

      // Hide all location names.
      .divider:first-child {
        display: none;
      }

      .divider:nth-child(2) {
        width: 40%;
      }

      .office-link {
        color: $black;
        border-color: $black;
        text-transform: capitalize;

        svg {
          fill: $black;
        }

        &.details,
        &.details + br {
          display: none;
        }

        &.office-type {
          margin-left: 1rem;
        }
      }

      &.single {
        .divider.buttons {
          display: flex;
          flex-direction: column;

          a:not(:last-child) {
            margin-bottom: 0;
          }

          a.phone {
            margin-bottom: 1.5rem;
          }

          .join {
            margin: 1.5rem 0 0;
          }
        }
      }

      &.no-address {
        .divider.buttons {
          display: flex;
          flex-direction: row;

          .join {
            margin: 0 0 0 1.5rem;
          }
        }
      }

      .button {
        background: $grey-darkest;
        color: $white;
        line-height: normal;
        min-width: 170px;

        &:focus {
          outline: 2px solid $white;
        }
      }

      .divider.buttons {
        white-space: nowrap;
        font-size: 1rem;
        display: flex;
        flex-direction: column;

        a:not(:last-child) {
          margin-bottom: 1.5rem;
        }

        // If the button has no title remove the padding so that it aligns with the social icons.
        &.no-title ~ .social-connect-icons {
          padding-top: 0;
        }
      }

      .social-connect-icons {
        flex-basis: 33.33%;
        padding-top: 2rem;
        padding-left: 8%;
      }

      &.no-address {
        justify-content: flex-start;
        align-items: center;

        .divider .phone {
          display: none;
        }

        .divider:nth-child(2) {
          width: auto;
        }

        .social-connect-icons {
          padding-top: 0;
          padding-left: 0;
        }

        &.vs-m {
          padding-bottom: 0;
        }
      }
    }

    .other-offices {
      padding-bottom: $column * 0.75;

      > button {
        padding-left: 0;
        padding-right: 0;
        background: transparent;
        appearance: none;
        color: $black;
        outline: none;

        &:after {
          content: '';
          display: inline-block;
          border-bottom: 3px solid;
          border-right: 3px solid;
          border-color: inherit;
          width: 0.5rem;
          height: 0.5rem;
          transform: rotate(45deg);
          margin-left: 0.3rem;
          vertical-align: 1px;
          transition: transform 0.5s;
        }

        &.open:after {
          transform: rotate(-135deg);
          vertical-align: -4px;
        }
      }
    }

    .slide-fade-enter-active {
      transition: all 0.3s ease;
    }

    .slide-fade-enter,
    .slide-fade-leave-to {
      max-height: 0;
      opacity: 0;
    }

    .accordion-section {
      color: $grey-darkest;
      padding-bottom: $column * 0.75;

      &.long-list {
        .accordion-description {
          display: none;
        }

        .accordion-container {
          .accordion-label {
            .accordion-trigger {
              font-size: 1rem;
              padding: 0;

              &::after {
                display: none;
              }
            }
          }
        }

        .active .location.cnt-fc {
          padding-left: 0;
          margin-top: 0;
        }
      }

      .accordion-container .accordion-label {
        .accordion-trigger:-moz-focusring {
          outline: 2px solid $white;
        }

        .accordion-trigger:focus-visible {
          outline: 2px solid $white;
        }
      }

      .long-title {
        display: none;
      }

      .accordion-description {
        .divider {
          p.street,
          p.address-title {
            font-size: 1rem;
            padding: 0 1.5rem 0 0;
          }

          a.office-link {
            font-size: 1rem;

            &.office-type {
              margin-left: 1rem;
            }
          }
        }

        h2.section-title {
          display: none;
        }

        .location.cnt-fc {
          padding-left: 0;
        }
      }

      @media screen and (min-width: $tablet) {
        &.long-list {
          .long-title {
            display: block;
          }

          .accordion-container {
            display: flex;
            flex-wrap: wrap;

            .accordion-container-item {
              border: none;
              width: 20%;

              &.first {
                padding: 1.5rem 0;
              }

              .accordion-label {
                display: inline-block;
                font-size: 1rem;
                border-bottom: 2px solid;
                padding: 0 0 2px 0;

                &:after {
                  display: none;
                }
              }

              input:checked + .accordion-label {
                opacity: 0.5;
              }

              input:checked ~ .accordion-description {
                display: none;
                height: 0;
              }

              .accordion-description {
                h2.section-title {
                  display: block;
                }
              }
            }
          }

          .accordion-description-container {
            opacity: 0;
            max-height: 0;
            transition: max-height 0.5s ease-in-out, opacity 0.4s ease-in-out;

            .section-title {
              padding: 3rem 0 1rem;
            }

            // Kansas City & Denver have multiple locations within the long list.
            .location:not(:last-child) {
              padding-bottom: $column * 0.75;
            }
          }

          .active {
            opacity: 1;
            max-height: 5000px;
          }
        }
      }
    }
  }

  .about {
    .office-subtitle,
    .office-sub-content {
      a {
        color: $black;
        border-bottom: 2px solid $black;
      }
    }

    .office-subtitle {
      font-size: 4rem;
      line-height: 4.5rem;
      font-weight: normal;
      margin: 0 0 2.5rem 0;
    }

    .office-sub-content {
      font-size: 1.12rem;
      line-height: 1.62rem;

      p {
        margin: 0;

        & + p {
          margin-top: 1rem;
        }
      }
    }
  }

  @include desktopOnly() {
    // Spacing between sections that are not the office details first section.
    > section:not(:first-of-type) + section {
      margin-top: $column;
      margin-bottom: $column;
    }
  }

  @include tablet() {
    .office-details {
      padding: 0;

      .location .divider:nth-child(2) {
        width: 55%;
      }

      .location {
        .office-link.office-type {
          margin-left: 0.5rem;
        }
      }

      .accordion-section {
        &.long-list {
          .accordion-container {
            .accordion-container-item {
              width: 25%;
            }

            .accordion-label {
              .accordion-trigger {
                font-size: 1rem;
              }
            }
          }
        }
      }
    }

    .about {
      .content {
        padding: 0 $column;
      }

      .office-subtitle {
        font-size: 2.25rem;
        line-height: 2.75rem;
        margin: 0 0 1.5rem 0;
      }

      .office-sub-content {
        max-width: none;
      }
    }
  }

  @include mobile() {
    // Spacing between sections that are not the office details first section.
    > section:not(:first-of-type) + section {
      margin-top: 3.75rem;
    }

    .office-details {
      padding: 0;

      > .location {
        padding-top: $column;
        padding-bottom: $column;
      }

      .location {
        &.single {
          .divider.buttons {
            flex-direction: column;

            .join {
              margin: 1.5rem 0 0;
            }
          }
        }

        .divider.buttons {
          .join {
            margin-left: 0;
          }
        }
        // Adding important to avoid complicated selectors, there should always be a padding
        // unless its empty.
        .social-connect-icons {
          padding-top: 1.5rem !important;
        }

        .social-connect-icons:empty {
          padding-top: 0 !important;
        }

        .social-connect-icons {
          padding-left: 0;
        }

        &.no-address {
          align-items: flex-start;
        }
      }

      // Give mobile locations more spacing.
      .additional-locations,
      .location + .location {
        margin-top: 2rem;
      }

      .accordion-section {
        .accordion-container {
          .accordion-label {
            .accordion-trigger {
              font-size: 1rem;
              padding: 0;

              &::after {
                display: block;
                border-bottom: 2px solid;
                border-right: 2px solid;
                width: 0.6rem;
                height: 0.6rem;
              }
            }
          }

          .accordion-description {
            display: block;
          }
        }

        .accordion-description-container {
          display: none;
        }
      }
    }

    .about {
      .content {
        padding: 0 $column;
      }

      .office-subtitle {
        font-size: 1.6rem;
        line-height: 2rem;
        margin: 0 0 1.5rem 0;
      }

      .office-sub-content {
        font-size: 1rem;
        line-height: 1.38rem;
      }
    }
  }
}
